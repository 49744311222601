var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: "Bottom_" + _vm.refresh, staticStyle: { margin: "0 20px 0 20px" } },
    [
      _c(
        "el-form",
        {
          ref: "ElForm",
          attrs: {
            model: _vm.formData,
            "label-width": "250px",
            rules: _vm.rules,
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Code analytique :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.code_analytique))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Compte auxiliaire client :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.compte_auxiliaire))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  style: _vm.editDateOuvertureChantier ? "padding-top:6px" : "",
                  attrs: { span: Number(_vm.spanCol1) }
                },
                [
                  _vm._v("\n      Date d'ouverture de chantier : \n      "),
                  !_vm.editDateOuvertureChantier &&
                  _vm.canEdit() &&
                  ![
                    _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                    _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                    _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                  ].includes(_vm.affaire.affaire.status)
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:affaire",
                              value: "editer",
                              expression: "'editer'",
                              arg: "affaire"
                            }
                          ],
                          staticStyle: { display: "inline" }
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              type: "success",
                              size: "mini",
                              circle: "",
                              icon: "el-icon-edit"
                            },
                            on: { click: _vm.handleChangeDateOuvertureChantier }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm.editDateOuvertureChantier
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can:affaire",
                            value: "editer",
                            expression: "'editer'",
                            arg: "affaire"
                          }
                        ]
                      },
                      [
                        _c("el-date-picker", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "##/##/####",
                              expression: "'##/##/####'"
                            }
                          ],
                          key: _vm.formData.date_ouverture_chantier,
                          attrs: {
                            type: "date",
                            format: "dd/MM/yyyy",
                            "value-format": "dd/MM/yyyy",
                            placeholder: "Choisissez un jour",
                            size: "mini"
                          },
                          model: {
                            value: _vm.formData.date_ouverture_chantier,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "date_ouverture_chantier",
                                $$v
                              )
                            },
                            expression: "formData.date_ouverture_chantier"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-close"
                            },
                            on: { click: _vm.handleCancelDateOuvertureChantier }
                          },
                          [_vm._v("Annuler")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-check"
                            },
                            on: { click: _vm.handleSaveDateOuvertureChantier }
                          },
                          [_vm._v("Enregistrer")]
                        )
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("toDate")(
                              _vm.affaire.affaire.date_ouverture_chantier
                            )
                          ) +
                          "\n      "
                      )
                    ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  style: _vm.editDateReception ? "padding-top:6px" : "",
                  attrs: { span: Number(_vm.spanCol1) }
                },
                [
                  _vm._v("\n      Date de réception : \n      "),
                  !_vm.editDateReception &&
                  _vm.canEdit() &&
                  ![
                    _vm.CONST.AFFAIRE.STATUS.SLEEPING.value,
                    _vm.CONST.AFFAIRE.STATUS.CLOSED.value,
                    _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
                  ].includes(_vm.affaire.affaire.status)
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "can",
                              rawName: "v-can:affaire",
                              value: "editer",
                              expression: "'editer'",
                              arg: "affaire"
                            }
                          ],
                          staticStyle: { display: "inline" }
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              type: "success",
                              size: "mini",
                              circle: "",
                              icon: "el-icon-edit"
                            },
                            on: { click: _vm.handleChangeDateReception }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm.editDateReception
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "can",
                            rawName: "v-can:affaire",
                            value: "editer",
                            expression: "'editer'",
                            arg: "affaire"
                          }
                        ]
                      },
                      [
                        _c("el-date-picker", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "##/##/####",
                              expression: "'##/##/####'"
                            }
                          ],
                          key: _vm.formData.date_reception,
                          attrs: {
                            type: "date",
                            format: "dd/MM/yyyy",
                            "value-format": "dd/MM/yyyy",
                            placeholder: "Choisissez un jour",
                            size: "mini"
                          },
                          model: {
                            value: _vm.formData.date_reception,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "date_reception", $$v)
                            },
                            expression: "formData.date_reception"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-close"
                            },
                            on: { click: _vm.handleCancelDateReception }
                          },
                          [_vm._v("Annuler")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "mini",
                              icon: "el-icon-check"
                            },
                            on: { click: _vm.handleSaveDateReception }
                          },
                          [_vm._v("Enregistrer")]
                        )
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("toDate")(_vm.affaire.affaire.date_reception)
                          ) +
                          "\n      "
                      )
                    ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Interlocuteur Maitre d'ouvrage :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.contact_maitre_ouvrage))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Téléphone :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.telephone))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Mail :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.mail))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Adresse :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.adresse1))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Complément :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.adresse2))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Ville :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.ville))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: Number(_vm.spanCol1) } }, [
                _vm._v("Code postal :")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: Number(_vm.spanCol2) } }, [
                _vm._v(_vm._s(_vm.affaire.affaire.code_postal))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }