"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

var apiWorkflow = _interopRequireWildcard(require("@/api/workflow"));

var _vuex = require("vuex");

var _listEtape = _interopRequireDefault(require("@/components/listEtape"));

var _listSecteurAct = _interopRequireDefault(require("@/components/listSecteurAct"));

var _listUserProfil = _interopRequireDefault(require("@/components/listUserProfil"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    formData: Object,
    title: String,
    action: String
  },
  components: {
    listEtape: _listEtape.default,
    listSecteurAct: _listSecteurAct.default,
    listUserProfil: _listUserProfil.default
  },
  data: function data() {
    return {
      selection: [],
      rules: {
        etape: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        key_role: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        niveau: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      },
      niveaux: [{
        value: 0,
        label: 'Saisie'
      }, {
        value: 1,
        label: 'Validation niveau 1'
      }, {
        value: 2,
        label: 'Validation niveau 2'
      }],
      liens: [{
        value: 'createur',
        label: 'Créateur de l\'affaire'
      }, {
        value: 'chef_projet',
        label: 'Chef de projet'
      }, {
        value: 'conducteur_travaux',
        label: 'Conducteur de travaux'
      }, {
        value: 'ingenieur_etude',
        label: 'Ingénieur d\'étude'
      }, {
        value: 'responsable_secteur',
        label: 'Responsable de secteur'
      }]
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['user']), {
    idSociete: function idSociete() {
      return this.user.id_societe;
    },
    societeName: function societeName() {
      return this.user.societe_name;
    }
  }),
  methods: {
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiWorkflow.insert(data).then(function (results) {
        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);
      }).catch(function (error) {
        _this2.$message(error);

        _this2.$loader(false);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      apiWorkflow.update(this.formData.id, data).then(function (results) {
        _this3.$message('Mise à jour effectuée avec succès');

        _this3.$loader(false);
      }).catch(function (error) {
        _this3.$message(error);

        _this3.$loader(false);
      });
    },
    getFormData: function getFormData() {
      return {
        id_societe: this.idSociete,
        etape: this.formData.etape,
        id_secteur_act: this.formData.id_secteur_act,
        key_role: this.formData.key_role,
        niveau: this.formData.niveau,
        lien_affaire: this.formData.lien_affaire
      };
    }
  }
};
exports.default = _default;